import React, { Component } from 'react';

import { Link } from 'react-router-dom';

import { ReactComponent as Logo } from '../../Assets/images/HeaderImages/Logo.svg';
import { ReactComponent as NodeHeader } from '../../Assets/images/HeaderImages/NodeHeader.svg';

class Header extends Component { 
  
  linkClicksLogo = () => {

      var currentPage = document.getElementById("currentPage");
      currentPage.textContent =  " _ homepage";
      
  }

  render() {
    return (
          <header>
            <div className="logo-container-header">                    
                                  <Link to="/" onClick={this.linkClicksLogo} title="Link to home page">
                                      <Logo />                      
                                  </Link>   
              <NodeHeader />
            </div>    
          </header>
        );
    }
}

export default Header;
import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux'
import { createStore, applyMiddleware, compose } from 'redux';
import thunk from 'redux-thunk'

import reducers from './Reducers'

import App from './App';

import GA4React from "ga-4-react";

const store = createStore(reducers, compose(applyMiddleware(thunk)))

const ga4react = new GA4React('G-LPMJS4VKEW')

  ga4react.initialize().then((ga4) => {
    ga4.pageview('path')
    ga4.gtag('event','pageview','path') // or your custom gtag event
  },(err) => {
    console.error(err)
  })
  
ReactDOM.render(
  <Provider store={store}>
      <App />
  </Provider>,
   document.getElementById('root'));
export const MenuPages = [
    {
        id: 1,
        buttonText: 'homepage',
        title: 'Vanja Borcic homepage',
        url: '/',
        className: 'nav-links',
        ids: 'homepage'
    },
    {
        id: 2,
        buttonText: 'web dev UX/UI',
        title: 'Vanja\'s web development page',
        url: '/web-development-ux-ui/',
        className: 'nav-links',
        ids: 'webdeveloper'
    },
    {
        id: 3,
        buttonText: 'art',
        title: 'Vanja\'s art portfolio page',
        url: '/art-gallery-portfolio/',
        className: 'nav-links',
        ids: 'art'
    },
    {
        id: 4,
        buttonText: 'available art',
        title: 'Vanja\'s available art for sale page',
        url: '/available-art-gallery/',
        className: 'nav-links',
        ids: 'available art'
    },
    {
        id: 5,
        buttonText: 'contact & social',
        title: 'Contact Vanja',
        url: '/contact/',
        className: 'nav-links',
        ids: 'contact'
    }
]
//console.log(JSON.stringify(MenuPages));
import React from 'react';

import { ReactComponent as NodeBodyBottom } from '../../Assets/images/HomepageImages/NodeBodyBottom.svg';
import { ReactComponent as IconFacebook } from '../../Assets/images/HomepageImages/IconFacebook.svg';
import { ReactComponent as IconLinkedIn } from '../../Assets/images/HomepageImages/IconLinkedIn.svg';
import { ReactComponent as IconInstagram } from '../../Assets/images/HomepageImages/IconInstagram.svg';

function SocialIconsComponent() {
    return (
        <div className="container">

            <NodeBodyBottom />

            <a rel="noopener noreferrer" href="https://www.linkedin.com/in/vanja-borcic-45ab2096" title="Vanja's professional LinkedIn account" target="_blank">< IconLinkedIn /></a>
            <a rel="noopener noreferrer" href="fb://vanjasart" title="Vanja's personal Facebook page" target="_blank"><IconFacebook /></a>
            <a rel="noopener noreferrer" href="https://www.instagram.com/vanjaborcic/" title="Vanja's personal Instagram account" target="_blank"><IconInstagram /></a>

        </div>
    );
  }
  
  export default SocialIconsComponent;
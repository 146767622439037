import React from 'react';
import { Link } from 'react-router-dom';

const updateCurrentDirectoryHomepage = (e) => {
  var currentPage = document.getElementById("currentPage");
  currentPage.textContent =  "_ homepage";
  window.scrollTo({
    top: 0,
    left: 0,
    behavior: 'smooth'
  });
};

const updateCurrentDirectoryWeb = (e) => {
  var currentPage = document.getElementById("currentPage");
  currentPage.textContent =  "_ web dev UX/UI";
  window.scrollTo({
    top: 0,
    left: 0,
    behavior: 'smooth'
  });
};

const updateCurrentDirectoryArt = (e) => {
  var currentPage = document.getElementById("currentPage");
  currentPage.textContent =  "_ art";
  window.scrollTo({
    top: 0,
    left: 0,
    behavior: 'smooth'
  });
};

const updateCurrentDirectoryAvailable = (e) => {
  var currentPage = document.getElementById("currentPage");
  currentPage.textContent =  "_ available art";
  window.scrollTo({
    top: 0,
    left: 0,
    behavior: 'smooth'
  });
};

const updateCurrentDirectoryContact = (e) => {
  var currentPage = document.getElementById("currentPage");
  currentPage.textContent =  "_ contact & social";
  window.scrollTo({
    top: 0,
    left: 0,
    behavior: 'smooth'
  });
};

function Footer() {   
    let currentYear = new Date().getFullYear();  
    return (
      <footer>
          <p className="footer-pages">
            <span><Link to="/" title="Link to homepage" onClick={updateCurrentDirectoryHomepage}>homepage</Link></span>&sdot;
            <span><Link to="/web-development-ux-ui/" title="Link to web development page" onClick={updateCurrentDirectoryWeb}>web development</Link></span>&sdot;
            <span><Link to="/art-gallery-portfolio/" title="Link to past and present artwork portfolio" onClick={updateCurrentDirectoryArt}>art</Link></span>&sdot;
            <span><Link to="/available-art-gallery/" title="Link to available art for sale" onClick={updateCurrentDirectoryAvailable}>available art</Link></span>&sdot;
            <span><Link to="/contact/" title="Link to contact me page" onClick={updateCurrentDirectoryContact}>contact</Link></span>
          </p>

          <p><small>Copyright &copy; { currentYear.toString() } Vanja Borcic. All rights reserved.</small></p>
          <p><small>All images on this website are original, created by, and property of Vanja Borcic.</small></p>
      </footer>
    );
  }
  
  export default Footer;
import React, { Fragment } from 'react';

import './Assets/css/style.min.css';

//****************routing
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';

import ScrollToTop from './Components/Header/ScrollToTop';

//****************header, menu, footer
import Header from './Components/Header/Header';
import Menu from './Components/Header/Menu';
import Footer from './Components/Footer/Footer';

//****************pages
import HomePage from './Pages/HomePage';
import WebDeveloperPage from './Pages/WebDeveloperPage';
import ArtPage from './Pages/ArtPage';
import AvailableArtPage from './Pages/AvailableArtPage';
import ContactPage from './Pages/ContactPage';
import pageMissing from './Pages/404';

const App = () => {
  
  return (
    <Router>
        <Fragment>
          <ScrollToTop />
            <div className="App">
              <Header/>
              <Menu/>
                <div className="container-fluid">
                  <div className="inner-container">

                    <Switch>
                      <Route path="/" exact component={HomePage}></Route> 
                      <Route path="/web-development-ux-ui" component={WebDeveloperPage}></Route> 
                      <Route path="/art-gallery-portfolio" component={ArtPage}></Route> 
                      <Route path="/available-art-gallery" component={AvailableArtPage}></Route> 
                      <Route path="/contact" component={ContactPage}></Route>
                      <Route path='*' exact={true} component={pageMissing} /> 
                    </Switch> 

                  </div> 
                </div>
            <Footer/>
            </div>
        </Fragment>
    </Router>  
  );
}

export default App;
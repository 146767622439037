import React, { Component } from 'react';
import {Helmet} from "react-helmet";
//import { Link } from 'react-router-dom';

import HomepageAnimatedBanner from '../Components/CustomComponents/HomepageAnimatedBanner';

class pageNotFound extends Component {
    render() {
        return (

            <div className="404-page-wrapper">

                <Helmet>
                  <meta
                    name="description"
                    content="-Vsite- page not found." />
                  <link rel="canonical" href={window.location.href} />
                  <title>-Vsite- Page Not Found</title>
                </Helmet>

                <HomepageAnimatedBanner/>

                  <div className="container">

                    <h1>Page not found...</h1>

                  </div>

            </div>

        )
    }
}
    
export default pageNotFound;
import React, { useState } from 'react';

import { withStyles } from '@material-ui/core/styles';
import { TextField, Button, Paper, Container, CssBaseline } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles';

import { useDispatch } from 'react-redux';

import { createContact } from '../../Actions/contacts';

const ArtSignupForm = () => {

    const formRef = React.useRef();
    const classes = useStyles();
    const dispatch = useDispatch();

    const [postData, setPostData] = useState({
        firstName: '', 
        lastName: '', 
        email: ''
    })

        const handleSubmit = (e) => {
            e.preventDefault();
            dispatch(createContact(postData));
            console.log(postData)
            formMessage()

        }

        const formMessage = () => {
          document.getElementById('formWrapper').innerHTML = "<div className=\"formMessage\"><p>Form is successfully submited. Thank you!</p></div>"
        }

    return (

      

        <Container component="main" maxWidth="xs" id="formWrapper">
            <CssBaseline />
                <Paper className={classes.paper}>
                    <form id="mainForm" ref={formRef} className={classes.form} autoComplete="off" onSubmit={handleSubmit}>
                        <TextField variant="outlined" autoComplete="fname" required fullWidth name="firstName" label="First Name" value={postData.firstName} onChange={(e) => setPostData({ ...postData, firstName: e.target.value })} />
                        <TextField variant="outlined" autoComplete="lname" required fullWidth name="lastName" label="Last Name" value={postData.lastName} onChange={(e) => setPostData({ ...postData, lastName: e.target.value })} />
                        <TextField type="email" variant="outlined" autoComplete="email" required fullWidth name="email" label="Your Email" value={postData.email} onChange={(e) => setPostData({ ...postData, email: e.target.value })} />
                        <StyledButton id="submitButton" className={classes.submit} fullWidth color="primary" size="large" type="submit" onClick={() => formRef.current.reportValidity()}>
                            Sign up
                        </StyledButton>
                    </form>
                </Paper>
        </Container>
    )
}

export default ArtSignupForm;

const useStyles = makeStyles((theme) => ({
    paper: {
      marginTop: theme.spacing(8),
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
    },
    form: {
      width: '100%', // Fix IE 11 issue.
      marginTop: '25px',
    },
    submit: {
      margin: theme.spacing(3, 0, 2),
    },
  }));

  const StyledButton = withStyles({
    root: {
      background: 'linear-gradient(45deg, #2787EA 30%, #1C67D2 90%)',
      borderRadius: 3,
      border: 0,
      color: 'white',
      height: 48,
      width: '100%',
      padding: '0 30px',
    },
    label: {
      textTransform: 'capitalize',
      color: 'white'
    },
  })(Button);
import Terraformer from '../../Assets/images/GenericGallery/tesla-terraformer-europa-station.jpg';
import BigHorn from '../../Assets/images/GenericGallery/big-horn.jpg';
import Converter from '../../Assets/images/GenericGallery/converter.jpg';
import Doggledoo from '../../Assets/images/GenericGallery/doggledoo.jpg';
import Galaxtraz from '../../Assets/images/GenericGallery/galaxtraz-high-orbit-prison.jpg';
import HalleyIncident from '../../Assets/images/GenericGallery/the-halley-incident.jpg';
import PlanetRevivingStation from '../../Assets/images/GenericGallery/planetary-reviving-station.jpg';
import Tengu from '../../Assets/images/GenericGallery/tengu.jpg';
import Venus from '../../Assets/images/GenericGallery/venus.jpg';
import HelixTree from '../../Assets/images/GenericGallery/helix-tree.jpg';
import TruthIsAll from '../../Assets/images/GenericGallery/truth-is-all-there-is.jpg';
import SoullingPunk from '../../Assets/images/GenericGallery/soulling-punk.jpg';
import Lagertha from '../../Assets/images/GenericGallery/lagertha.jpg';
import FlashAndI from '../../Assets/images/GenericGallery/flash-and-i.jpg';
import TheyTookToTheSky from '../../Assets/images/GenericGallery/they-took-to-the-sky.jpg';
import TwoToedArtist from '../../Assets/images/GenericGallery/two-toed-artist.jpg';
import SpaceRays from '../../Assets/images/GenericGallery/space-rays.jpg';
import TexturePractice from '../../Assets/images/GenericGallery/texture-practice.jpg';
import ImpliedFuture from '../../Assets/images/GenericGallery/implied-future.jpg';
import Angler from '../../Assets/images/GenericGallery/angler.jpg';
import AsTheSeasons from '../../Assets/images/GenericGallery/as-the-seasons-we-change.jpg';
import Cephalopidoptera from '../../Assets/images/GenericGallery/cephalopidoptera.jpg';
import Eighty from '../../Assets/images/GenericGallery/80.jpg';
import GoldenHands from '../../Assets/images/GenericGallery/golden-hands.jpg';
import Arzon from '../../Assets/images/GenericGallery/arzon.jpg';
import Cove from '../../Assets/images/GenericGallery/cove.jpg';
import Everything from '../../Assets/images/GenericGallery/everything.jpg';
import HomicidalJane from '../../Assets/images/GenericGallery/homicidal-jane.jpg';
import NewGeneration from '../../Assets/images/GenericGallery/new-generation.jpg';
import Obstacles from '../../Assets/images/GenericGallery/obstacles.jpg';
import OctopodaChloris from '../../Assets/images/GenericGallery/octopoda-chloris.jpg';
import ReminiscentOfHome from '../../Assets/images/GenericGallery/reminiscent-of-home.jpg';
import Predator from '../../Assets/images/GenericGallery/predator.jpg';
import Segmento from '../../Assets/images/GenericGallery/segmento.jpg';
import TheQueenSkySnake from '../../Assets/images/GenericGallery/the-queen-sky-snake.jpg';
import VoidAwaits from '../../Assets/images/GenericGallery/void-awaits.jpg';
import Destiny from '../../Assets/images/GenericGallery/destiny.jpg';
import LongStory from '../../Assets/images/GenericGallery/a-long-story.jpg';
import Vikings from '../../Assets/images/GenericGallery/vikings.jpg';
import TheWizard from '../../Assets/images/GenericGallery/the-wizard.jpg';
import TheUnderWaterExcursion from '../../Assets/images/GenericGallery/underwater-excursion.jpg';
import PokerFacePossum from '../../Assets/images/GenericGallery/poker-face-possum.jpg';
import JumpingFizz from '../../Assets/images/GenericGallery/jumping-fizz.jpg';
import StargateMoab from '../../Assets/images/GenericGallery/stargate-moab.jpg';
import Multidexter from '../../Assets/images/GenericGallery/multidexter.jpg';
import IsleOfDeath from '../../Assets/images/GenericGallery/isle-of-death.jpg';
import TheGoldenAgeOfAquarius from '../../Assets/images/GenericGallery/the-golden-age-of-aquarius.jpg';
import Evangelium from '../../Assets/images/GenericGallery/evangelium.jpg';
import Zeus from '../../Assets/images/GenericGallery/zeus.jpg';

export const mosaicImages = [
  {
    src: Zeus,
    width: 2,
    height: 2,
    loading: 'lazy',
    title: 'Vanja Borcic - Zeus',
    alt: 'Vanja Borcic - Zeus - Acrylic on Gessobord'
  }
  ,
  {
    src: Evangelium,
    width: 1,
    height: 2,
    loading: 'lazy',
    title: 'Vanja Borcic - Evangelium',
    alt: 'Vanja Borcic - Evangelium - Acrylic on Canvas'
  }
  ,
  {
    src: TheGoldenAgeOfAquarius,
    width: 1,
    height: 1.2,
    loading: 'lazy',
    title: 'Vanja Borcic - The Golden Age of Aquarius',
    alt: 'Vanja Borcic - The Golden Age of Aquarius - Acrylic on Canvas'
  }
  ,
  {
    src: Multidexter,
    width: 1,
    height: 2,
    loading: 'lazy',
    title: 'Vanja Borcic - Multidexter',
    alt: 'Vanja Borcic - Multidexter - Acrylic on Canvas'
  }
  ,
  {
    src: IsleOfDeath,
    width: 1,
    height: 1.2,
    loading: 'lazy',
    title: 'Vanja Borcic - Isle of Death',
    alt: 'Vanja Borcic - Isle of Death - Acrylic on Canvas'
  }
  ,
  {
    src: Terraformer,
    width: 1,
    height: 1,
    loading: 'lazy',
    title: 'Vanja Borcic Tesla Terraformer - Europa Moon',
    alt: 'Vanja Borcic - Tesla Terraformer Europa Moon - Acrylic on GessoBord'
  }
  ,
  {
    src: HelixTree,
    width: 1,
    height: 1.3,
    loading: 'lazy',
    title: 'Vanja Borcic Helix Tree',
    alt: 'Vanja Borcic - Helix Tree - Acrylic on GessoBord'
  }
  ,
  {
    src: Cephalopidoptera,
    width: 1,
    height: 1,
    loading: 'lazy',
    title: 'Vanja Borcic Cephalopidoptera',
    alt: 'Vanja Borcic - Cephalopidoptera  - Acrylic on Canvas'
  }
  ,
  {
    src: PokerFacePossum,
    width: 1,
    height: 4,
    loading: 'lazy',
    title: 'Vanja Borcic Poker Face Possum',
    alt: 'Vanja Borcic - Poker Face Possum  - Acrylic and Spray Paint on Wood'
  }
  ,
  {
    src: AsTheSeasons,
    width: 4,
    height: 1,
    loading: 'lazy',
    title: 'Vanja Borcic As The Seasons We Change',
    alt: 'Vanja Borcic - As The Seasons We Change - Acrylic on GessoBord'
  }
  ,
  {
    src: Predator,
    width: 2.5,
    height: 2,
    loading: 'lazy',
    title: 'Vanja Borcic Predator',
    alt: 'Vanja Borcic - Predator - Acrylic on GessoBord'
  }
  ,
  {
    src: NewGeneration,
    width: 2,
    height: 1.1,
    loading: 'lazy',
    title: 'Vanja Borcic New Generation',
    alt: 'Vanja Borcic - New Generation - Acrylic on Wood Panel'
  }
  ,
  {
    src: OctopodaChloris,
    width: 1.8,
    height: 2.2,
    loading: 'lazy',
    title: 'Vanja Borcic Octopoda Chloris',
    alt: 'Vanja Borcic - Octopoda Chloris - Acrylic on GessoBord'
  }
  ,
  {
    src: JumpingFizz,
    width: 2.1,
    height: 1,
    loading: 'lazy',
    title: 'Vanja Borcic Jumping Fizz',
    alt: 'Vanja Borcic - Jumping Fizz - Acrylic on Wood'
  }
  ,
  {
    src: Obstacles,
    width: 3.5,
    height: 1,
    loading: 'lazy',
    title: 'Vanja Borcic Obstacles',
    alt: 'Vanja Borcic - Obstacles - Acrylic on Wood Panel'
  }
  ,
  {
    src: ReminiscentOfHome,
    width: 1,
    height: 2,
    loading: 'lazy',
    title: 'Vanja Borcic Truth Is All There Is',
    alt: 'Vanja Borcic - Truth Is All There Is - Acrylic on GessoBord'
  }
  ,
  {
    src: TruthIsAll,
    width: 2.6,
    height: 2.2,
    loading: 'lazy',
    title: 'Vanja Borcic Truth Is All There Is',
    alt: 'Vanja Borcic - Truth Is All There Is - Acrylic on GessoBord'
  }
  ,
  {
    src: BigHorn,
    width: 1,
    height: 1.7,
    loading: 'lazy',
    title: 'Vanja Borcic Big Horn',
    alt: 'Vanja Borcic - Big Horn - Acrylic on Canvas'
  }
  ,
  {
    src: SoullingPunk,
    width: 1,
    height: 1,
    loading: 'lazy',
    title: 'Vanja Borcic Soulling Punk',
    alt: 'Vanja Borcic - Soulling Punk  - Ink Pen on GessoBord'
  }
  ,
  {
    src: Destiny,
    width: 1,
    height: 2,
    loading: 'lazy',
    title: 'Vanja Borcic Destiny',
    alt: 'Vanja Borcic - Destiny  - Acrylic and Charcoal on Wood Panel'
  }
  ,
  {
    src: LongStory,
    width: 3.7,
    height: 1,
    loading: 'lazy',
    title: 'Vanja Borcic A Long Story',
    alt: 'Vanja Borcic - A Long Story  - Acrylic and Charcoal on Wood Panel'
  }
  ,
  {
    src: Converter,
    width: 1,
    height: 1.5,
    loading: 'lazy',
    title: 'Vanja Borcic Converter',
    alt: 'Vanja Borcic - Converter - Acrylic on GessoBord'
  }
  ,
  {
    src: Arzon,
    width: 1,
    height: 1,
    loading: 'lazy',
    title: 'Vanja Borcic Arzon',
    alt: 'Vanja Borcic - Arzon - Acrylic on GessoBord'
  }
  ,
  {
    src: Everything,
    width: 2,
    height: 1,
    loading: 'lazy',
    title: 'Vanja Borcic Everything',
    alt: 'Vanja Borcic - Everything - Acrylic on GessoBord'
  }
  ,
  {
    src: HomicidalJane,
    width: 1,
    height: 1.5,
    loading: 'lazy',
    title: 'Vanja Borcic Homicidal Jane',
    alt: 'Vanja Borcic - Homicidal Jane - Acrylic on GessoBord'
  }
  ,
  {
    src: ImpliedFuture,
    width: 2.2,
    height: 1,
    loading: 'lazy',
    title: 'Vanja Borcic Implied Future',
    alt: 'Vanja Borcic - Implied Future - Acrylic on GessoBord'
  }
  ,
  {
    src: Lagertha,
    width: 1,
    height: 1,
    loading: 'lazy',
    title: 'Vanja Borcic Lagertha',
    alt: 'Vanja Borcic - Lagertha - Digital Painting Clip Studio'
  }
  ,
  {
    src: GoldenHands,
    width: 1.7,
    height: 1,
    loading: 'lazy',
    title: 'Vanja Borcic Golden Hands',
    alt: 'Vanja Borcic - Golden Hands - Acrylic on Wood Panel'
  }
  ,
  {
    src: Angler,
    width: 1,
    height: 1.5,
    loading: 'lazy',
    title: 'Vanja Borcic Angler',
    alt: 'Vanja Borcic - Angler - Acrylic on GessoBord'
  }
  ,
  {
    src: Doggledoo,
    width: 4,
    height: 2,
    loading: 'lazy',
    title: 'Vanja Borcic Doggledoo',
    alt: 'Vanja Borcic - Doggledoo - Digital Painting Clip Studio'
  }
  ,
  {
    src: FlashAndI,
    width: 1,
    height: 1.3,
    loading: 'lazy',
    title: 'Vanja Borcic Flash And I',
    alt: 'Vanja Borcic - Flash And I - Acrylic on Strathmore paper'
  }
  ,
  {
    src: Cove,
    width: 1,
    height: 1,
    loading: 'lazy',
    title: 'Vanja Borcic Cove',
    alt: 'Vanja Borcic - Cove - Acrylic on Wood Panel'
  }
  ,
  {
    src: PlanetRevivingStation,
    width: 1,
    height: 1.5,
    loading: 'lazy',
    title: 'Vanja Borcic Planetary Reviving Station',
    alt: 'Vanja Borcic - Planetary Reviving Station - Acrylic on GessoBord'
  }
  ,
  {
    src: TheyTookToTheSky,
    width: 1,
    height: 1.5,
    loading: 'lazy',
    title: 'Vanja Borcic They Took To The Sky',
    alt: 'Vanja Borcic - They Took To The Sky - Acrylic on GessoBord'
  }
  ,
  {
    src: Segmento,
    width: 1,
    height: 1.2,
    loading: 'lazy',
    title: 'Vanja Borcic Segmento',
    alt: 'Vanja Borcic - Segmento - Mixed Media on Wood Panel'
  }
  ,
  {
    src: Tengu,
    width: 1,
    height: 1.5,
    loading: 'lazy',
    title: 'Vanja Borcic Tengu',
    alt: 'Vanja Borcic - Tengu - Digital Painting Clip Studio'
  }
  ,
  {
    src: TwoToedArtist,
    width: 1,
    height: 1,
    loading: 'lazy',
    title: 'Vanja Borcic Two Toed Artist',
    alt: 'Vanja Borcic - Two Toed Artist - Acrylic on GessoBord'
  }
  ,
  {
    src: TheQueenSkySnake,
    width: 2.8,
    height: 1,
    loading: 'lazy',
    title: 'Vanja Borcic The Queen Sky Snake',
    alt: 'Vanja Borcic - The Queen Sky Snake - Acrylic and Spray Paint on Wood Panel'
  }
  ,
  {
    src: VoidAwaits,
    width: 1,
    height: 1.5,
    loading: 'lazy',
    title: 'Vanja Borcic Void Awaits',
    alt: 'Vanja Borcic - Void Awaits - Acrylic on Wood Panel'
  }
  ,
  {
    src: HalleyIncident,
    width: 1,
    height: 1,
    loading: 'lazy',
    title: 'Vanja Borcic The Halley Incident',
    alt: 'Vanja Borcic - The Halley Incident - Acrylic on GessoBord'
  }
  ,
  {
    src: SpaceRays,
    width: 1,
    height: 1.5,
    loading: 'lazy',
    title: 'Vanja Borcic Space Rays',
    alt: 'Vanja Borcic - Space Rays - Acrylic on GessoBord'
  }
  ,
  {
    src: StargateMoab,
    width: 3,
    height: 2,
    loading: 'lazy',
    title: 'Vanja Borcic Stargate Moab',
    alt: 'Vanja Borcic - Stargate Moab  - Acrylic on GessoBord'
  }
  ,
  {
    src: Venus,
    width: 1,
    height: 1.5,
    loading: 'lazy',
    title: 'Vanja Borcic Venus',
    alt: 'Vanja Borcic - Venus - Acrylic on Canvas'
  }
  ,
  {
    src: TexturePractice,
    width: 1,
    height: 1.4,
    loading: 'lazy',
    title: 'Vanja Borcic Texture Practice',
    alt: 'Vanja Borcic - Texture Practice - Acrylic on GessoBord'
  }
  ,
  {
    src: Vikings,
    width: 1,
    height: 2,
    loading: 'lazy',
    title: 'Vanja Borcic Vikings',
    alt: 'Vanja Borcic - Vikings - Acrylic on Canvas'
  }
  ,
  {
    src: TheWizard,
    width: 2,
    height: 1.3,
    loading: 'lazy',
    title: 'Vanja Borcic The Wizard',
    alt: 'Vanja Borcic - The Wizard - Acrylic on GessoBord'
  }
  ,
  {
    src: TheUnderWaterExcursion,
    width: 1.5,
    height: 2.2,
    loading: 'lazy',
    title: 'Vanja Borcic The Under Water Excursion',
    alt: 'Vanja Borcic - The Under Water Excursion - Acrylic on GessoBord'
  }
  ,
  {
    src: Eighty,
    width: 1,
    height: 1.4,
    loading: 'lazy',
    title: 'Vanja Borcic Eighty',
    alt: 'Vanja Borcic - Eighty - Acrylic on GessoBord'
  }
  ,
  {
    src: Galaxtraz,
    width: 1,
    height: 1,
    loading: 'lazy',
    title: 'Vanja Borcic Galaxtraz High Orbit Prison',
    alt: 'Vanja Borcic - Galaxtraz High Orbit Prison - Acrylic on GessoBord'
  }
  ];
  
  export const lightboxImages = mosaicImages.map(({ src, alt, caption, title }) => ({
    src,
    alt,
    caption, 
    title
  }));  
import React from 'react';

import { ReactComponent as Stars } from '../../Assets/images/HomepageImages/Stars.svg';

import Image from 'react-image-webp';

function HomepageAnimatedBanner() {
    return (
        <div className="main-animated-banner">


            <div className="banner-container">

                <div className="universe-banner-wrapper">

                <Image
                    src={require('../../Assets/images/HomepageImages/gallaxy-background.jpg')}
                    webp={require('../../Assets/images/HomepageImages/gallaxy-background.webp')}
                    width="1920"
                    height="1080"
                    alt="Vanja Borcic - Homepage - Universe Banner"
                />

                </div>    

                <div className="hero">
                    <div className="stars">
                        <Stars />
                    </div>
                </div> 

                <div className="star-small-wrapper">
                        <div className="star-small"></div>               
                </div>

                <div className="star-small-two-wrapper">
                        <div className="star-small-two"></div>               
                </div>

                <div className="star-small-three-wrapper">
                        <div className="star-small-three"></div>               
                </div>

                <div className="star-sun-wrapper">
                        <div className="star-sun"></div>               
                </div>

                <div className="star-medium-wrapper">
                        <div className="star-medium"></div>               
                </div>

            </div>

        </div>
    );
  }
  
  export default HomepageAnimatedBanner;
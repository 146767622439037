import { FETCH_ALL, CREATE } from "../Constants/ActionTypes";
import * as api from '../API'

//Action Creators
export const getContacts = () => async (dispatch) => {
    try {
        const { data } = await api.fetchContacts()
        dispatch({ type: FETCH_ALL, payload: data })
    } catch (error) {
        console.log(error.message)
    }
} 

export const createContact = (contact) => async (dispatch) => {
    try {
        const { data } = await api.createContact(contact)
        dispatch({ type: CREATE, payload: data })
        console.log("createContact executed!!!!")
    } catch (error) {
        console.log(error)
    }
}
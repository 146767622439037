import React from 'react'
import Zoom from 'react-medium-image-zoom'
import 'react-medium-image-zoom/dist/styles.css'
import { ReactComponent as IconEmail } from '../../Assets/images/HomepageImages/IconEmail.svg';

const Multidexter = require('../../Assets/images/GenericGallery/multidexter.jpg');
const Vikings = require('../../Assets/images/GenericGallery/vikings.jpg');
const IsleOfDeath = require('../../Assets/images/GenericGallery/isle-of-death.jpg');
const TheGoldenAgeOfAquarius = require('../../Assets/images/GenericGallery/the-golden-age-of-aquarius.jpg');
const Evangelium = require('../../Assets/images/GenericGallery/evangelium.jpg');
const Zeus = require('../../Assets/images/GenericGallery/zeus.jpg');

const AvailableArtworkComponent = () => {
return (
<div>
<figure>      
    <Zoom>
        <img
          alt="Zeus - Vanja Borcic available painting for purchase"
          src={Zeus}
          width="240"
          
        />
      </Zoom>
      <figcaption><p>Zeus - 36" x 36" Tryptich - Acrylic and Montana Spay Paint on GessoBord <br /> $900</p>
      <p>
        <a rel="noopener noreferrer" title="Email Vanja About Zeus Painting" href="mailto:vanjawebdev@gmail.com?subject=Questions or Comments about Zeus painting">
          <IconEmail />
        </a>
      </p>
      </figcaption>
</figure>
<figure>      
    <Zoom>
        <img
          alt="Evangelium - Vanja Borcic available painting for purchase"
          src={Evangelium}
          width="240"
          
        />
      </Zoom>
      <figcaption><p>Evangelium - 24" x 48" - Acrylic on Wood Panel <br /> $1800</p>
      <p>
        <a rel="noopener noreferrer" title="Email Vanja About Evangelium Painting" href="mailto:vanjawebdev@gmail.com?subject=Questions or Comments about Evangelium painting">
          <IconEmail />
        </a>
      </p>
      </figcaption>
</figure>
<figure>      
    <Zoom>
        <img
          alt="The Golden Age of Aquarius - Vanja Borcic available painting for purchase"
          src={TheGoldenAgeOfAquarius}
          width="240"
          
        />
      </Zoom>
      <figcaption><p>The Golden Age of Aquarius - 48" x 60" - Acrylic on Canvas <br /> $1200</p>
      <p>
        <a rel="noopener noreferrer" title="Email Vanja About Multidexter Painting" href="mailto:vanjawebdev@gmail.com?subject=Questions or Comments about Multidexter painting">
          <IconEmail />
        </a>
      </p>
      </figcaption>
</figure> 
<figure>      
    <Zoom>
        <img
          alt="Isle of Death - Vanja Borcic available painting for purchase"
          src={IsleOfDeath}
          width="240"
          
        />
      </Zoom>
      <figcaption><p>Isle of Death - 48" x 60" - Acrylic on Canvas <br /> $1200</p>
      <p>
        <a rel="noopener noreferrer" title="Email Vanja About Multidexter Painting" href="mailto:vanjawebdev@gmail.com?subject=Questions or Comments about Multidexter painting">
          <IconEmail />
        </a>
      </p>
      </figcaption>
  </figure>  
  <figure>      
    <Zoom>
        <img
          alt="Multidexer - Vanja Borcic available painting for purchase"
          src={Multidexter}
          width="240"
          
        />
      </Zoom>
      <figcaption><p>Multidexter - 24" x 48" - Acrylic on Canvas <br /> $900</p>
      <p>
        <a rel="noopener noreferrer" title="Email Vanja About Multidexter Painting" href="mailto:vanjawebdev@gmail.com?subject=Questions or Comments about Multidexter painting">
          <IconEmail />
        </a>
      </p>
      </figcaption>
  </figure>  
    <figure>
      <Zoom>
        <img
          alt="Vikings - Vanja Borcic available painting for purchase"
          src={Vikings}
          width="240"
          
        />
      </Zoom>
      <figcaption><p>Vikings - 24" x 48" - Acrylic on Canvas <br /> $900</p>
      <p>
        <a rel="noopener noreferrer" title="Email Vanja About Vikings Painting" href="mailto:vanjawebdev@gmail.com?subject=Questions or Comments about Vikings painting">
          <IconEmail />
        </a>
      </p>
      </figcaption>
    </figure>
</div>
    )
}

export default AvailableArtworkComponent
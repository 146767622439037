import React from 'react';
import {Helmet} from "react-helmet";

import WebDeveloperAnimatedBanner from '../Components/CustomComponents/WebDeveloperAnimatedBanner';
import SocialIconsComponent from '../Components/CustomComponents/SocialIconsComponent';

import { ReactComponent as NodeBodyWeb } from '../Assets/images/WebDeveloperPageImages/NodeBodyWeb.svg';

import { ReactComponent as NodeBodyLinkedIn } from '../Assets/images/WebDeveloperPageImages/NodeBodyLinkedIn.svg';
import { ReactComponent as IconLinkedIn } from '../Assets/images/HomepageImages/IconLinkedIn.svg';

import { ReactComponent as Logo } from '../Assets/images/HeaderImages/Logo.svg';

function WebDeveloperPage() {
  return (

    <div className="web-developer-page">
      <Helmet>
          <meta
          name="description"
          content="Vanja Borcic - Colorado based Front End Web Developer, 
          Fine Digital Graphic Artist, and UX/UI Designer - Web Development and UX/UI Design page."
        />
          <link rel="canonical" href={window.location.href} />
          <title>Vanja Borcic | Front End Web Developer | UX/UI Designer</title>
      </Helmet>

      <WebDeveloperAnimatedBanner />

        <div className="web-page-intro container padding-LR-25">

        <h1 className="clear-both">Front End Web Developer | UX/UI Designer</h1>  

              <NodeBodyWeb />

              <p className="web-page-intro-p padding-T-10 clear-both">I have extensive front-end
              web development and UX/UI design experience in various programming languages, frameworks, libraries,
              content management systems and applications.</p>
              
          </div>   
         
          <div className="clear-both container web-linkedin-icon">

            <NodeBodyLinkedIn />

            <a rel="noopener noreferrer" href="https://www.linkedin.com/in/vanja-borcic-45ab2096" title="Vanja's professional LinkedIn account" target="_blank"><IconLinkedIn /></a> 

          </div>

          <div className="web-page-bottom container padding-LR-25 clear-both">  

          <h2 className="center-this">Experience...</h2>      

              <p>
              HTML, CSS, SCSS, JavaScript, Jquery, JSX, C#, PHP, PowerShell, Ajax, JSON, Gulp, Grunt, SQL, MySQL, 
              NoSQL, MongoDB, Mongoose, Node.js, Express, Microsoft and Apache Servers, 
              AWS, Azure, DotNetNuke, React JS, WordPress, Angular JS, ASP.NET, GitHub, Microsoft TFS.
              </p>  

          </div>

          <div className="container body-vsite-div clear-both">

            <div className="body-logo-wrapper">

              <span><Logo /></span><span><h2>- www.vcasso.com -</h2></span>

            </div>

              <p className="padding-LR-25">Built with React JS |
              Node JS |
              Express |
              Mongoose |
              Mongo DB | 
              Gulp |
              Material UI |
              CSS 3 and SVG animate |
              Illustrator | 
              Photoshop | 
              Clip Studio Paint

              </p>

          </div> 

          <SocialIconsComponent />

   </div>
  );
}

export default WebDeveloperPage;
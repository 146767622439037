import React from 'react';

import { ReactComponent as Stars } from '../../Assets/images/HomepageImages/Stars.svg';

import { ReactComponent as Satellite } from '../../Assets/images/WebDeveloperPageImages/Satellite.svg';

import Image from 'react-image-webp';

function WebDeveloperAnimatedBanner() {
    return (
        <div className="main-animated-banner">

            <div className="banner-container">

                <div className="universe-banner-wrapper">

                <Image
                    src={require('../../Assets/images/WebDeveloperPageImages/gallaxy-background-web.jpg')}
                    webp={require('../../Assets/images/WebDeveloperPageImages/gallaxy-background-web.webp')}
                    width="1920"
                    height="1080"
                    alt="Vanja Borcic - Web Developer Page - Universe Banner"
                />

                </div>

                <div className="hero">
                    <div className="stars">
                        <Stars />
                    </div>

                    <Satellite />

                </div>  

                <div className="star-small-wrapper">
                        <div className="star-small"></div>               
                </div>

                <div className="star-small-two-wrapper">
                        <div className="star-small-two"></div>               
                </div>

                <div className="star-small-three-wrapper">
                        <div className="star-small-three"></div>               
                </div>

                <div className="star-medium-wrapper">
                        <div className="star-medium"></div>               
                </div>

            </div> 

        </div>
    );
  }
  
  export default WebDeveloperAnimatedBanner;
import React from 'react';

import { ReactComponent as Stars } from '../../Assets/images/HomepageImages/Stars.svg';

import Image from 'react-image-webp';

//cube images
import Terraformer from '../../Assets/images/GenericGallery/tesla-terraformer-europa-station.jpg';
import Galaxtraz from '../../Assets/images/GenericGallery/galaxtraz-high-orbit-prison.jpg';
import HalleyIncident from '../../Assets/images/GenericGallery/the-halley-incident.jpg';
import PlanetRefreshStation from '../../Assets/images/GenericGallery/planetary-reviving-station.jpg';
import Cephalopidoptera from '../../Assets/images/GenericGallery/cephalopidoptera.jpg';
import Venus from '../../Assets/images/GenericGallery/venus.jpg';

function ArtPageAnimatedBanner() {
    return (
        <div className="main-animated-banner">

            <div className="banner-container">

                <div className="universe-banner-wrapper">
                
                <Image
                    src={require('../../Assets/images/ArtPageImages/gallaxy-background-art.jpg')}
                    webp={require('../../Assets/images/ArtPageImages/gallaxy-background-art.webp')}
                    width="1920"
                    height="1080"
                    alt="Vanja Borcic - Universe Banner"
                />

                </div>

                <div className="hero">
                    <div className="stars">
                        <Stars />
                    </div>
                </div>  

                <div className="star-small-wrapper">
                        <div className="star-small"></div>               
                </div>

                <div className="star-small-two-wrapper">
                        <div className="star-small-two"></div>               
                </div>

                <div className="star-small-three-wrapper">
                        <div className="star-small-three"></div>               
                </div>

                <div className="star-medium-wrapper">
                        <div className="star-medium"></div>               
                </div>

                <div className="cube-div">
                    <div className="cube">
                        <div className="face front"><img src={Terraformer} alt="Tesla Terraformer Europa Moon original painting" width="300" height="300" /></div>
                        <div className="face back"><img src={Galaxtraz} alt="Galaxtraz original painting" width="300" height="300"/></div>
                        <div className="face right"><img src={HalleyIncident} alt="Halley Incident original painting" width="300" height="300"/></div>
                        <div className="face left"><img src={PlanetRefreshStation} alt="Planet Refresh Station original painting" width="300" height="300"/></div>
                        <div className="face top"><img src={Venus} alt="Venus original painting" width="300" height="300"/></div>
                        <div className="face bottom"><img src={Cephalopidoptera} alt="Cephalopidoptera original painting" width="300" height="300"/></div>
                    </div>
                 </div>

            </div> 

        </div>
    );
  }
  
  export default ArtPageAnimatedBanner;
import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";

const GridImage = ({ key, index, left, top, photo, onClick }) => {
  const { height, width, src, alt, loading } = photo;

  return (
    <ImageContainer
      key={`${key}-${index}`}
      index={index}
      onClick={e => onClick(e, { index })}
      style={{ left, top, height, width }}
    >

        <Image src={src} alt={alt} width={width} height={height} loading={loading} />

    </ImageContainer>
  );
};

GridImage.propTypes = {
  key: PropTypes.string.isRequired,
  index: PropTypes.number.isRequired,
  left: PropTypes.number.isRequired,
  top: PropTypes.number.isRequired,
  containerHeight: PropTypes.number.isRequired,
  onClick: PropTypes.func.isRequired,
  photo: PropTypes.shape({
    alt: PropTypes.string.isRequired,
    height: PropTypes.number.isRequired,
    width: PropTypes.number.isRequired,
    src: PropTypes.string.isRequired
  }).isRequired
};

export default GridImage;

const ImageContainer = styled.div`
  z-index: 900;
  transition: border-color 0.2s linear;
  display: block;
  position: absolute;
  cursor: pointer;
  border: 0;
`;

const Image = styled.img`
  width: inherit;
  height: inherit;
  position: absolute;
`;

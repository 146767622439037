import React, { Component } from 'react';

import { MenuPages } from "./MenuPages";

import { NavLink } from 'react-router-dom';

import { ReactComponent as MenuIcon } from '../../Assets/images/HeaderImages/MenuIcon.svg';
import { ReactComponent as OpenMenuBackgrounda } from '../../Assets/images/HeaderImages/OpenMenuBackground_TopLeft.svg';
import { ReactComponent as OpenMenuBackgroundb } from '../../Assets/images/HeaderImages/OpenMenuBackground_TopRight.svg';
import { ReactComponent as OpenMenuBackgroundc } from '../../Assets/images/HeaderImages/OpenMenuBackground_Bottom.svg';

class Menu extends Component {   

    state = { clicked: false }

    constructor (props) {
      super(props);
      this.inputRef = React.createRef();
    }

    handleClick = () => {

      this.setState({ clicked: !this.state.clicked })

    }
  
    openMenuClose = () => {

        if (this.state.clicked) {

          this.setState({ clicked: !this.state.clicked })
  
        }   
          //scroll to top on every menu click so the menu/window is in zoom
          window.scrollTo({
            top: 0,
            left: 0,
            behavior: 'smooth'
          });

          this.inputRef.current.classList.toggle('open-menu');
          
          var currentPageLoad = document.getElementsByClassName("active");
          var currentPage = document.getElementById("currentPage");
          currentPage.textContent =  currentPageLoad[0].textContent;

    }

  componentDidMount() {

    var currentPageLoad = document.getElementsByClassName("active");
    var currentPage = document.getElementById("currentPage");
    var currentPagePath = window.location.pathname;

    if (currentPagePath === "/" || currentPagePath === "/web-development-ux-ui/" 
    || currentPagePath === "/art-gallery-portfolio/" || currentPagePath === "/contact/" || currentPagePath === "/available-art-gallery/") {

      currentPage.textContent = currentPageLoad[0].textContent;

    }
    else {
      
      currentPage.textContent = " _ 404...page not found";

    }
    
    let timer = document.getElementById("currentDateTime");
    timer = setInterval(() => this.setState({ time: Date.now() }), 1000);
  }

  
  linkClicks = (event) => {

    if (!this.state.clicked) {

      var currentPage = document.getElementById("currentPage");
      currentPage.textContent =  event.target.textContent;

    }   

  }

    closeMenu = () => {

       this.openMenuClose();
      
    }
    
  render() {


    let currentYear = new Date().toLocaleString()
    return (

      <nav className="navbar">

              <div className="pages-list open-menu" ref={this.inputRef}>
          
                  <ul className="menu-list">

                              {MenuPages.map((item, index) =>(
                                <li key={(index, item.id)}>
                                  <NavLink title={item.title} id={item.ids} to={item.url} exact activeClassName="active" onClick={this.linkClicks}>
                                  <span className="hide-active">_</span>   {item.buttonText}                      
                                  </NavLink>  
                                </li>     
                              ))}  
                       
                  </ul>    

                   <OpenMenuBackgrounda />
                        
                          <div className="current-page-wrapper">

                            <p>vsite... online</p>

                            <p>menu... open</p>

                            <p>

                              <span>current directory... </span>
                              
                              <span id="currentPage"></span>

                            </p>

                            <p>react js</p>

                          </div>

                          <div className="current-time-date-wrapper">

                              <p id="currentDateTime">{ currentYear.toString() }</p>

                          </div>

                        <OpenMenuBackgroundb />
                        <OpenMenuBackgroundc />

        </div>          

        <div className="menu-icon" onClick={this.handleClick}>
                      
            <MenuIcon onClick={this.openMenuClose} className={this.state.clicked ? 'closed' : 'open'}/>

        </div>  

      </nav>  

    );
  }
}

export default Menu;
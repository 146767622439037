import React, { useState } from "react";

import Lightbox from "./ArtPageMainPortfolioComponents/CoolLightbox";
import ImageMosaic from "./ArtPageMainPortfolioComponents/ImageMosaic";

import { mosaicImages, lightboxImages } from "./ArtPageMainPortfolioImages";


const FineArtImageGallery = () => {
  const [isOpen, setOpen] = useState(false);
  const [currentImageIndex, setCurrentIndex] = useState(0);

  return (
    <>
      {/* react-photo-gallery */}
      <ImageMosaic
        images={mosaicImages}
        handleClick={(e, { index }) => {
          setCurrentIndex(index);
          setOpen(true);
        }}
      />
      {/* react-spring-lightbox */}
      <Lightbox
        currentImageIndex={currentImageIndex}
        setCurrentIndex={setCurrentIndex}
        isOpen={isOpen}
        onClose={() => setOpen(false)}
        images={lightboxImages}
      />
    </>
  );
};

export default FineArtImageGallery;
import React from 'react';
import {Helmet} from "react-helmet";
import { Link } from 'react-router-dom';

import HomepageAnimatedBanner from '../Components/CustomComponents/HomepageAnimatedBanner';
import SocialIconsComponent from '../Components/CustomComponents/SocialIconsComponent';

import { ReactComponent as NodeBody } from '../Assets/images/HomepageImages/NodeBody.svg';
import { ReactComponent as NodeBodyArtWeb } from '../Assets/images/HomepageImages/NodeBodyArtWeb.svg';

import { ReactComponent as IconEmail } from '../Assets/images/HomepageImages/IconEmail.svg';
import { ReactComponent as IconArt } from '../Assets/images/HomepageImages/IconArt.svg';
import { ReactComponent as IconWeb } from '../Assets/images/HomepageImages/IconWeb.svg';

const updateCurrentDirectoryArt = (e) => {
  var currentPage = document.getElementById("currentPage");
  currentPage.textContent =  "_ art";
  window.scrollTo({
    top: 0,
    left: 0,
    behavior: 'smooth'
  });
};

const updateCurrentDirectoryWeb = (e) => {
  var currentPage = document.getElementById("currentPage");
  currentPage.textContent =  "_ web dev UX/UI";
  window.scrollTo({
    top: 0,
    left: 0,
    behavior: 'smooth'
  });
};

function HomePage() {
  return (

<div className="home-wrapper">
                
                <Helmet>
                  <meta
                    name="description"
                    content="Vanja Borcic - Colorado based Front End Web Developer, 
                    Fine Digital Graphic Artist, and UX/UI Designer personal website -Vsite-." />
                  <link rel="canonical" href={window.location.href} />
                  <title>Vanja Borcic | Front End Web Developer Artist UX/UI Designer</title>
                </Helmet>

    <HomepageAnimatedBanner/>

    <div className="container">
            
      <NodeBody />

      <a rel="noopener noreferrer" title="Email Vanja" href="mailto:vanjawebdev@gmail.com?subject=Email From VanjaBorcic.com Homepage"><IconEmail /></a>

        <div className="clear-both padding-LR-25 home-intro-wrap">

          <h1>Web Developer | Artist | Designer</h1>
          <p className="p-one">Hi! I am Vanja.</p>
          <p className="p-two">I'm a Colorado-based front-end web developer, 
          UX/UI designer, fine, digital, and graphic artist.</p>

        </div> 

    </div>

    <div className="container">

       <NodeBodyArtWeb />

        <Link to="/art-gallery-portfolio/" title="Vanja's art portfolio page" onClick={updateCurrentDirectoryArt}><IconArt /></Link>
        <Link to="/web-development-ux-ui/" title="Vanja's web development page" onClick={updateCurrentDirectoryWeb}><IconWeb /></Link>


    </div>

          <div className="about-me clear-both padding-LR-25">

            <h2 className="center-this">About...</h2>

            <p>I was born in beautiful Bosnia and Herzegovina, where I lived for the first seventeen years of my life.
              In the year 1996, after four year long civil war, I moved to the US with my parents and my brother. 
              We landed in Des Moines, Iowa, an ideal place for us to start our lives in this different new world. 
              We lived there for twenty happy years.</p>
            <p>Eventually, we all relocated to Colorado, where we enjoy easy access to nature and great weather.</p>
            <p>In my free time, I love to work on advancing my coding skills or creating new art late into the night.
              I also like to work out, mountain bike, hike, camp, and most importantly spend valuable time with my family
              and friends, which I find are the best drivers for all of my inspiration and desire to excel.
            </p>

    </div>     

<SocialIconsComponent />

</div>  
   
 );
}

export default HomePage;
import React from 'react';
import {Helmet} from "react-helmet";
import AvailableArtPageAnimatedBanner from '../Components/CustomComponents/AvailableArtPageAnimatedBanner';
import SocialIconsComponent from '../Components/CustomComponents/SocialIconsComponent';

import { ReactComponent as NodeBodyWeb } from '../Assets/images/WebDeveloperPageImages/NodeBodyWeb.svg';

import { ReactComponent as ButtonArt } from '../Assets/images/GraphicGallery/ButtonArt.svg';

import ArtSignupForm from '../Components/ArtSignupForm/ArtSignupForm';

import AvailableArtworkComponent from '../Components/CustomComponents/AvailableArtworkComponent';

const AvailableArtPage = () => {

    return (
      
      <div className="available-art-page">
        <Helmet>
            <meta
            name="description"
            content="Vanja Borcic - Colorado based Front End Web Developer, 
            Fine Digital Graphic Artist, and UX/UI Designer - Available Art."
            />
            <link rel="canonical" href={window.location.href} />
            <title>Vanja Borcic | Available Art for Sale</title>
        </Helmet>

        <AvailableArtPageAnimatedBanner/>

          <div className="container">

          <NodeBodyWeb />

            <h1 className="center-this">Available Art Gallery</h1>

            <div id="available-artwork-wrapper">

              <AvailableArtworkComponent />

              <p className="center-this">Shipping and Taxes Included</p>

            </div>

            <div className="available-art-form-wrapper">

              <ButtonArt/>

                <h4>If you would like to be added to my mailing list to be notified 
                  about the future happenings, or whenever the new available artwork is
                   are added to this page, please fill out the form below!</h4>

                  <ArtSignupForm />

            </div>

          </div>

        <SocialIconsComponent />

        </div>
  );
}

export default AvailableArtPage;
import React from 'react';
import {Helmet} from "react-helmet";
import ContactPageAnimatedBanner from '../Components/CustomComponents/ContactPageAnimatedBanner';

import { ReactComponent as NodeBodyWeb } from '../Assets/images/WebDeveloperPageImages/NodeBodyWeb.svg';

import { ReactComponent as IconEmail } from '../Assets/images/HomepageImages/IconEmail.svg';
import { ReactComponent as IconFacebook } from '../Assets/images/HomepageImages/IconFacebook.svg';
import { ReactComponent as IconLinkedIn } from '../Assets/images/HomepageImages/IconLinkedIn.svg';
import { ReactComponent as IconInstagram } from '../Assets/images/HomepageImages/IconInstagram.svg';

import { ReactComponent as JourneyBoat } from '../Assets/images/GraphicGallery/JourneyBoat.svg';

function ContactPage() {
  return (

<div className="contact-page-wrapper">
    <Helmet>
        <meta
        name="description"
        content="Vanja Borcic - Colorado based Front End Web Developer, 
        Fine Digital Graphic Artist, and UX/UI Designer - Contact Information."
      />
        <link rel="canonical" href={window.location.href} />
        <title>Vanja Borcic | Contact Me</title>
    </Helmet>

    <ContactPageAnimatedBanner/>

        <div className="container">

          <NodeBodyWeb />
            
            <h1 className="center-this">Contact Info</h1>

<div className="contact-page-inner">
<p>
  <a className="hyperlink" rel="noopener noreferrer" title="Email Vanja" href="mailto:vanjawebdev@gmail.com?subject=Email From VanjaBorcic.com Homepage"><IconEmail />
  My Email: vanjawebdev@gmail.com</a>
</p>
<p>            
  <a className="hyperlink" rel="noopener noreferrer" href="https://www.linkedin.com/in/vanja-borcic-45ab2096" title="Vanja's professional LinkedIn account" target="_blank">< IconLinkedIn />
  Professional LinkedIn Account</a>
</p>
<p>
  <a className="hyperlink" rel="noopener noreferrer" href="fb://vanjasart" title="Vanja's personal Facebook page" target="_blank"><IconFacebook />
  Personal Facebook Page</a>
</p>           
<p>
  <a className="hyperlink" rel="noopener noreferrer" href="https://www.instagram.com/vanjaborcic/" title="Vanja's personal Instagram account" target="_blank"><IconInstagram />
  Personal Instagram Account</a>
</p>
</div> 
</div>   

<div className="center-this">
  <JourneyBoat />
</div>

</div>

 );
}

export default ContactPage;
import React from 'react';
import {Helmet} from "react-helmet";

import ArtPageAnimatedBanner from '../Components/CustomComponents/ArtPageAnimatedBanner';

import { ReactComponent as NodeBodyWeb } from '../Assets/images/WebDeveloperPageImages/NodeBodyWeb.svg';

import FineArtImageGallery from "../Components/CustomComponents/ArtPageMainPortfolio";

import SocialIconsComponent from '../Components/CustomComponents/SocialIconsComponent';

function ArtPage() {
  return (
    
    <div className="art-page">
      <Helmet>
          <meta
          name="description"
          content="Vanja Borcic - Colorado based Front End Web Developer, 
          Fine Digital Graphic Artist, and UX/UI Designer - Fine Digital Graphic and 3D art gallery portfolio."
          />
          <link rel="canonical" href={window.location.href} />
          <title>Vanja Borcic | Fine Digital Graphic 3D Art Portfolio</title>
      </Helmet>

      <ArtPageAnimatedBanner />

        <div className="container padding-LR-25">

              <h1 className="clear-both">Visual Arts</h1>
              
              <NodeBodyWeb />

              <p className="art-intro-p">I, more than anything love to practice my imagination, to create visually pleasing imagery and to entertain
              the people that want to be entertained with it. One of my favorite outlets for that were and always will be visual arts.               
              I like to make up strange worlds, tell hidden stories, and send ambiguous messages through made-up imagery and non-existent creatures. 
              I enjoy applying layer after layer of washes, brushstrokes, and lines late into the night. Semi-loud music in the background...
              cats somewhere in the vicinity...nothing else... 
              </p>

              <p>I've been an artist my whole life. Since I was born into a family of artists, I was highly influenced by my uncles and older
                cousins, and started emulating them at an early age. Then, throughout my art education and career i was guided
                 by teachers, professors, and most of all and always was mostly inspired and influenced by my artist friends - past and present.
              </p>

              <p className="art-intro-p-2">Below are some of the examples of my artwork. 
                If you are interested in purchasing art pieces you can always contact me, or visit the
              <a title="Vanja Borcic available artworks page" href="/available-art-gallery/" className="hyperlink">
                 &nbsp;available art&nbsp;</a>
              page.</p>

        </div>
        <div className="art-div-bottom">      
              
              <h2>Fine and Digital</h2>

              <FineArtImageGallery />

        </div>

        <SocialIconsComponent />
 
   </div>
  );
}

export default ArtPage;